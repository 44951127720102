import React from 'react';
import Layout from '../../components/App/Layout';
import Navbar from '../../components/App/Navbar';
import PageBanner from '../../components/Common/PageBanner';
import Footer from '../../components/App/Footer';
import JobList from '../../components/Jobs/JobList';
import SEO from '../../components/App/SEO';

const Jobs = () => {
    return (
        <Layout>
          <SEO
            title="採用情報"
            desc="採用情報です。"
            pathname={'/jobs'}
            type={'Article'}
            shortName="採用情報"
            createdAt="2021-03-02"
            updatedAt="2021-03-02"
          />
            <Navbar />
            <PageBanner
                pageTitle="採用情報"
                homePageText="Home"
                homePageUrl="/"
                activePageText="採用情報"
            />
                <JobList/>
            <Footer />
        </Layout>
    );
};

export default Jobs