import React from 'react'
import {Link} from 'gatsby'

const JobList = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-web"></i>
                            </div>
                            <h3>
                                <Link to="/jobs/web-designer">
                                Webデザイナー
                                </Link>
                            </h3>
                            <p>ビジュアルデザイン及びコーディング業務をお任せします。</p>

                            <Link className="view-details-btn" to="/jobs/web-designer" >
                                詳細をみる
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/jobs/developer">
                                システムエンジニア
                                </Link>
                            </h3>

                            <p>フロントエンド、サーバーサイドの開発全般をお任せします。</p>
                            
                            <Link className="view-details-btn" to="/jobs/developer">
                                詳細をみる
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-user"></i>
                            </div>

                            <h3>
                                <Link to="/jobs/customer-support">
                                    カスタマーサポート
                                </Link>
                            </h3>
                            <p>ユーザーからの問い合わせのカスタマーサポート業務をお任せします。</p>

                            <Link className="view-details-btn" to="/jobs/customer-support">
                                詳細を見る
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="#">
                                インフラエンジニア
                                </Link>
                            </h3>

                            <p>リタッチを支えるサーバーサイド・インフラの構築。運用・保守をお任せします。</p>
                            <Link className="view-details-btn" to="/jobs/infrastructure">
                                詳細をみる
                            </Link>
                            {/*<Link className="view-details-btn" to="/service-details">*/}
                            {/*    View Details*/}
                            {/*</Link>*/}
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-6">

                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">

                    </div>
                </div>
            </div>
        </section>
    )
};

export default JobList